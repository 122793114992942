<template>
  <div id="main-content">
    <Create
      :visible="createVisible"
      @close="() => this.createVisible = false"
      @created="onCreated"
    />
    <ResetPassword
      :user="user"
      :visible="resetPasswordVisible"
      @close="() => this.resetPasswordVisible = false"
      @reset="onPasswordReset"
    />
    <a-row :gutter="24">
      <a-col :span="4">
        <DFSAdminNavbar active="用户管理" />
      </a-col>
      <a-col :span="20">
        <div class="admin-content">
          <Search @search="onSearch" @create="onCreate" />
        </div>
        <a-table
          :loading="loading"
          :columns="columns"
          :data-source="users"
          :pagination="pagination"
          :row-key="record => record.username"
          @change="onChange"
        >
          <p slot="is-staff" slot-scope="text">
            <span v-if="text">是</span>
            <span v-else>否</span>
          </p>
          <span slot="date-joined" slot-scope="text">
            {{ text | moment }}
          </span>
          <span slot="action" slot-scope="record">
            <a @click="onResetPassword(record)">重置密码</a>
            <a-divider type="vertical" />
            <a
              v-if="record.is_staff"
              @click="onUpdate(record.username, {is_staff: false})"
            >
              设为普通账号
            </a>
            <a
              v-else
              @click="onUpdate(record.username, {is_staff: true})"
            >
              设为员工账号
            </a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="您确定删除该用户吗？"
              @confirm="onDestroy(record.username)"
            >
              <a>删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import DFSAdminNavbar from '@/components/DFSAdminNavbar';
import Search from './Search';
import {columns} from './utils';
import Create from './Create';
import {list, destroy, update} from '@/services/user';
import ResetPassword from "./ResetPassword";

export default {
  name: 'Content',
  components: {
    DFSAdminNavbar,
    Search,
    Create,
    ResetPassword
  },
  data() {
    return {
      loading: false,
      queryParams: {
        username: ''
      },
      columns: columns,
      pagination: {},
      createVisible: false,
      resetPasswordVisible: false,
      user: null,
      users: []
    };
  },
  methods: {
    fetch(queryParams) {
      this.loading = true;
      list(queryParams).then(({data}) => {
        this.loading = false;
        this.users = data['data']['results'];
        const pager = {...this.pagination};
        pager.total = data['data']['count'];
        this.pagination = pager;
      });
    },
    onChange(pagination) {
      const pager = {...this.pagination};
      pager.current = pagination.current;
      this.pagination = pager;
      const queryParams = {...this.queryParams};
      queryParams.page = pagination.current;
      this.fetch(queryParams);
    },
    onSearch(queryParams) {
      this.queryParams = {...queryParams};
      const pager = {...this.pagination};
      pager.current = 1;
      this.pagination = pager;
      this.fetch(queryParams);
    },
    onCreate() {
      this.createVisible = true;
    },
    onCreateClosed() {
      this.createVisible = false;
    },
    onCreated() {
      const queryParams = {...this.queryParams};
      const pager = {...this.pagination};
      pager.current = 1;
      this.pagination = pager;
      this.fetch(queryParams);
      this.createVisible = false;
    },
    onDestroy(username) {
      destroy(username).then(() => {
        const queryParams = {...this.queryParams};
        const pager = {...this.pagination};
        pager.current = 1;
        this.pagination = pager;
        this.fetch(queryParams);
        this.$message.info(`${username}删除成功。`);
      });
    },
    onUpdate(username, data) {
      update(username, data).then(() => {
        const queryParams = {...this.queryParams};
        if (this.pagination.current !== undefined) {
          queryParams.page = this.pagination.current;
        }
        this.fetch(queryParams);
        this.$message.info(`${username}更改成功。`);
      });
    },
    onResetPassword(user) {
      this.user = user;
      this.resetPasswordVisible = true;
    },
    onPasswordReset() {
      this.resetPasswordVisible = false;
      this.$message.info(`${this.user.username}更改成功。`);
    },
  },
  mounted() {
    this.fetch({});
  }
}
</script>


<style lang="stylus" scoped>
#main-content
  padding-top: 2rem

  .ops
    margin-bottom: 1rem
</style>
