<template>
  <a-form-model
      class="ops"
      layout="inline"
      :model="queryParams"
  >
    <a-form-model-item>
      <a-input
          placeholder="用户名"
          v-model="queryParams.username"
          @pressEnter="onSearch"
      />
    </a-form-model-item>
    <a-form-model-item>
      <a-button
          type="primary"
          icon="search"
          :style="{ marginRight: '1rem' }"
          @click="onSearch"
      >
        搜索
      </a-button>
      <a-button
          type="primary"
          icon="plus"
          @click="onCreate"
      >
        新建
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
export default {
  name: 'Search',
  data() {
    return {
      queryParams: {
        username: ''
      }
    };
  },
  methods: {
    onSearch() {
      this.$emit('search', this.queryParams);
    },
    onCreate() {
      this.$emit('create');
    }
  }
}
</script>
