<template>
  <a-modal
    title="新建用户"
    :visible="visible"
    :footer="null"
    @cancel="onClose"
  >
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{span: 4}"
      :wrapper-col="{span: 20}"
    >
      <a-form-model-item label="用户名" prop="username">
        <a-input
          v-model="form.username"
          placeholder="请输入用户名，例如admin、user1等"
        />
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="email">
        <a-input
          v-model="form.email"
          placeholder="请输入邮箱地址，例如admin@example.com"
        />
      </a-form-model-item>
      <a-form-model-item label="密码" prop="password">
        <a-input
          v-model="form.password"
          type="password"
          placeholder="请输入长度在8位以上的密码"
        />
      </a-form-model-item>
      <a-form-model-item label="重复密码" prop="confirmPassword">
        <a-input
          v-model="form.confirmPassword"
          type="password"
          placeholder="请重复输入上述密码请输入与第一次相同密码"
        />
      </a-form-model-item>
      <a-form-model-item label="员工账号" prop="isStaff">
        <a-switch v-model="form.isStaff" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{offset: 4}">
        <a-button
          type="primary"
          html-type="submit"
          @click="create"
        >
          提交
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {rules} from './utils';
import {create} from '@/services/user';


export default {
  name: 'Create',
  props: {
    visible: Boolean
  },
  data() {
    rules.confirmPassword.push({
      validator: this.validateConfirmPassword,
      trigger: 'blur'
    });
    return {
      form: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        isStaff: true,
      },
      rules,
    };
  },
  methods: {
    validateConfirmPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('不能为空。'));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入的密码不一致。"));
      } else {
        callback();
      }
    },
    create() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }
        const data = {
          username: this.form.username,
          email: this.form.email,
          password: this.form.password,
          is_staff: this.form.isStaff
        };
        create(data).then(() => {
          this.$emit('created');
          this.form = {
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
            isStaff: true
          };
        });
      });
    },
    onClose() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>
