import service from '@/utils/service';
import {dictToURI} from '@/utils/url';


export function exists(username) {
    return service({
        method: 'get',
        url: `/v1/users/exists/?username=${username}`
    });
}


export function create(data) {
    return service({
        method: 'post',
        url: '/v1/users/',
        data
    });
}


export function list(queryParams) {
    return service({
        method: 'get',
        url: '/v1/users/?' + dictToURI(queryParams)
    });
}


export function destroy(username) {
    return service({
        method: 'delete',
        url: `/v1/users/${username}/`
    });
}


export function update(username, data) {
    return service({
        method: 'patch',
        url: `/v1/users/${username}/`,
        data
    });
}


export function login(username, password) {
    return service({
        method: 'post',
        url: '/v1/users/login/',
        data: {
            'username': username,
            'password': password
        }
    });
}


export function me() {
    return service({
        method: 'get',
        url: '/v1/users/me/'
    });
}
