<template>
  <a-modal
      title="重置密码"
      :visible="visible"
      @cancel="onClose"
      @ok="onReset"
  >
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{span: 4}"
      :wrapper-col="{span: 20}"
    >
      <a-form-model-item label="密码" prop="password">
        <a-input
          type="password"
          v-model="form.password"
          placeholder="请输入长度在8位以上的密码"
        />
      </a-form-model-item>
      <a-form-model-item label="确认密码" prop="confirmPassword">
        <a-input
          type="password"
          v-model="form.confirmPassword"
          placeholder="请重复输入上述密码请输入与第一次相同密码"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {resetPasswordRules as rules} from './utils';
import {update} from '@/services/user';

export default {
  name: 'ResetPassword',
  props: {
    user: Object,
    visible: Boolean
  },
  data() {
    rules.confirmPassword.push({
      validator: this.validateConfirmPassword,
      trigger: 'blur'
    });
    return {
      rules,
      form: {
        password: '',
        confirmPassword: ''
      }
    };
  },
  methods: {
    validateConfirmPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('不能为空。'));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入的密码不一致。"));
      } else {
        callback();
      }
    },
    onClose() {
      this.$emit('close');
    },
    onReset() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }
        update(this.user.username, {
          password: this.form.password
        }).then(() => {
          this.$emit('reset');
        })
      });
    }
  }
}
</script>

<style scoped>
</style>
