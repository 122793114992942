import {exists} from '@/services/user';


export const columns = [
    {
        title: '用户名',
        dataIndex: 'username'
    },
    {
        title: '邮箱',
        dataIndex: 'email'
    },
    {
        title: '员工账号',
        dataIndex: 'is_staff',
        scopedSlots: {
            customRender: 'is-staff'
        }
    },
    {
        title: '注册时间',
        dataIndex: 'date_joined',
        scopedSlots: {
            customRender: 'date-joined'
        }
    },
    {
        title: '操作',
        scopedSlots: {
            customRender: 'action'
        }
    }
];


function usernameExists(rule, value, callback) {
    exists(value).then(({data}) => {
        if (data.data.exists) {
            callback(new Error('该用户名已被占用。'));
        } else {
            callback();
        }
    });
}

export const rules = {
    username: [
        {required: true, message: '用户名不能为空。', trigger: 'blur'},
        {validator: usernameExists, trigger: 'blur'},
    ],
    email: [
        {required: true, message: '邮箱地址不能为空。', trigger: 'blur'},
        {type: 'email', message: '请输入正确格式的邮箱地址。', trigger: 'blur'},
    ],
    password: [
        {required: true, message: '密码不能为空。', trigger: 'blur'},
        {min: 8, message: '密码长度至少为8。', trigger: 'blur'},
    ],
    confirmPassword: [
        {required: true, message: '密码不能为空。', trigger: 'blur'},
        {min: 8, message: '密码长度至少为8。', trigger: 'blur'},
    ]
};

export const resetPasswordRules = {
    password: [
        {required: true, message: '密码不能为空。', trigger: 'blur'},
        {min: 8, message: '密码长度至少为8。', trigger: 'blur'},
    ],
    confirmPassword: [
        {required: true, message: '密码不能为空。', trigger: 'blur'},
        {min: 8, message: '密码长度至少为8。', trigger: 'blur'},
    ]
};
